@import '~ethos-design-system/src/components/Media/Media.scss';

.componentWrapper {
  padding-top: var(--Space-14);
  padding-bottom: var(--Space-56);
}

.wrapper {
  display: flex;
  flex-direction: column;

  @include for-tablet-only {
  }

  @include for-laptop-and-up {
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
  }
}

.text {
  .eyebrow {
    color: var(--BrandForest);
    margin-bottom: var(--Space-32);
  }
  .heading {
    margin-bottom: var(--Space-32);
  }
  .subHeading {
    @include for-phone-only {
      margin-bottom: var(--Space-32);
    }
    margin-bottom: var(--Space-24);
  }

  &.imageSmall {
    @include for-laptop-only {
      width: 350px;
    }
    @include for-desktop-only {
      width: 650px;
    }
  }

  &.imageLarge {
    @include for-laptop-only {
      width: 300px;
    }
    @include for-desktop-only {
      width: 400px;
    }
  }
}

.imageWrapper {
  @include for-phone-only {
    margin-top: var(--Space-32);
  }
  @include for-tablet-only {
    margin-top: var(--Space-32);
  }
}

.textCta {
  margin-top: var(--Space-16);
  border-bottom: 1px solid rgba(0, 0, 0, 0.24);
  display: inline-block;
}

.ctaBelow {
  display: block;
  margin-top: var(--Space-32);
  @include for-laptop-and-up {
    display: none;
  }
}

.hideMobile {
  @include for-laptop-only {
    margin-top: var(--Space-64);
  }
  margin-top: var(--Space-48);
  display: block;
  @include for-phone-and-tablet {
    display: none;
  }
}

.secondaryImageWrapper {
  @include for-tablet-only {
    padding-top: var(--Space-32);
  }
  padding: var(--Space-24) 0;
}
